import React, { useState, useEffect,Component } from 'react';
import { HashRouter, Route, Switch } from 'react-router-dom';
import './scss/style.scss';
import * as firebase from 'firebase/app';

const loading = (
  <div className="pt-3 text-center">
    <div className="sk-spinner sk-spinner-pulse"></div>
  </div>
)

// Containers
const TheLayout = React.lazy(() => import('./containers/TheLayout'));

// Pages
const Login = React.lazy(() => import('./views/pages/login/Login'));
const Register = React.lazy(() => import('./views/pages/register/Register'));
const Page404 = React.lazy(() => import('./views/pages/page404/Page404'));
const Page500 = React.lazy(() => import('./views/pages/page500/Page500'));
const Tabs = React.lazy(() => import('./views/base/tabs/Tabs'));
const Tables = React.lazy(() => import('./views/base/tables/Tables'));

class App extends Component {

  componentDidMount = () => {

   let firebaseConfig = {
    apiKey:"AIzaSyCXE2EHMo5m4iDiWmAs0VZZYt4F2J5nRzM",
    authDomain:"enegeco-581f6.firebaseapp.com",
    databaseURL:"https://enegeco-581f6.firebaseio.com",
    projectId:"enegeco-581f6",
    storageBucket:"enegeco-581f6.appspot.com",
    messagingSenderId:"743374099451",
    appId:"1:743374099451:web:5912e9bfe279917bb3b15e",
    measurementId:"G-F35Q6YGFTB"
  }
  // const firebaseConfig = {
  //   apiKey: "AIzaSyA6QA71V2EO_WEbe4jc2wvo7j8yJikw49w",
  //   authDomain: "enegeco-demo.firebaseapp.com",
  //   databaseURL: "https://enegeco-demo.firebaseio.com",
  //   projectId: "enegeco-demo",
  //   storageBucket: "enegeco-demo.appspot.com",
  //   messagingSenderId: "393918655512",
  //   appId: "1:393918655512:web:0de8aac593af58f3d4276d"
  // };
  // let firebaseConfig = {
  //   apiKey: "AIzaSyBM9-IL6zT77IhO7V4NmAKNhzLTg48eIpk",
  //   authDomain: "enegeco-peace.firebaseapp.com",
  //   databaseURL: "https://enegeco-peace.firebaseio.com",
  //   projectId: "enegeco-peace",
  //   storageBucket: "enegeco-peace.appspot.com",
  //   messagingSenderId: "599705300449",
  //   appId: "1:599705300449:web:51536295eb36a26ee776a0"
  // }
      // console.log("this.firebaseConfig",firebaseConfig);

      let fireApp = firebase.initializeApp(firebaseConfig);

      // console.log("fireApp",fireApp);

  }

  render() {
    return (
      <HashRouter>
          <React.Suspense fallback={loading}>
            <Switch>
              <Route exact path="/" name="Login" render={props => <Login {...props}/>} />
                <Route exact path="/register" name="Register Page" render={props => <Register {...props}/>} />
              {/* <Route exact path="/404" name="Page 404" render={props => <Page404 {...props}/>} />
              <Route exact path="/500" name="Page 500" render={props => <Page500 {...props}/>} />  */}
               <Route path="/dashboard" name="Home" render={props => <TheLayout {...props}/>} />  
               <Route path="/consumptionBill" name="Home" render={props => <TheLayout {...props}/>} /> 
               <Route path="/configuration" name="Home" render={props => <TheLayout {...props}/>} />
               <Route path="/alerts" name="Home" render={props => <TheLayout {...props}/>} />  
            </Switch>
          </React.Suspense>
      </HashRouter>
    );
  }
}

export default App;
